import './footer.css'

const Footer = () => {
    return (
        <div id="footer">
            <p>2021 © PlayorSwipe. All rights reserved.</p>
        </div>
    );
};

export default Footer;