import React from 'react';

const Error404 = () => {
  return (
    <div className="home-page">
      <h3 style={{textAlign: 'center', marginTop: '2rem'}}>Page not found</h3>
    </div>
  );
};

export default Error404;